import React, { useState } from 'react';
import { useInterval } from '../hooks';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';
import resume from '../assets/Bryden_Fogelman_Resume.pdf';

const Wrapper = styled.div`
    padding: 50px;

    a {
        font-weight: bold;
        text-decoration: none;
        color: black;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
            opacity: 0.5;
        }
    }
`;

const Clickable = styled.span`
    font-weight: bold;
    text-decoration: none;
    color: black;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`

const FavoriteIcon = styled.span`
    i {
        transition: all 0.5s;
        font-size: 28px;
        vertical-align: middle;
        padding-left: 5px;
        opacity: 0.9;
        color: ${({ isActive }) => isActive ? '#e74c3c' : 'black'};
        font-size: ${({ isActive }) => isActive ? '32px' : '28px'};
    }
`;

const projects = [
    {
        name: 'Algorithm for Quantifying Ocular Torsion',
        description: 'Developed an algorithm to efficiently and accurately measure the rotation of the eye about the line of sight to enable studying of the vestibular system.',
        tags: [
            'Python',
            'Image Processing'
        ]
    },
    {
        name: 'Autonomous Robot for Crop Monitoring',
        description: 'Responsible for the software systems needed to create a 3-axis movement robot that can wirelessly communicate with a crop monitoring sensor and autonomously navigate throughout a greenhouse.',
        tags: [
            'Raspberry Pi',
            'Control Theory',
            'Protoyping'
        ],
    }
];

const experiences = [
    {
        name: 'Tesla',
        description: 'Managed several internal web application tools used by numerous engineering teams to improve critical manufacturing processes.',
        tags: [
            'React',
            'Redux',
            'Django'
        ],
    },
];

const Tag = styled.span`
    background-color: rgba(231,76,60, 0.8);
    color: white;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #f5f5f5;
    font-size: 12px;
    margin-right: 5px;
    font-weight: normal;
`;

const ProjectList = ({ title, projects }) => (
    <div>
        <h2>{title}</h2>
        {projects.map(project => <Project key={project.name} {...project} />)}
    </div>
);

const Project = ({ name, description, tags }) => (
    <ProjectWrapper>
        <h3>
            {name}
        </h3>
        <p>
            <span className="tags">
                {tags.map(tag => (
                    <Tag
                        key={tag}
                    >
                        {tag}
                    </Tag>)
                )}
            </span>
        </p>
        <p>
            {description}
        </p>
        {/* <p>
            <Clickable className="see-more">
                See More <MaterialIcon icon="arrow_forward"/>
            </Clickable>
        </p> */}
    </ProjectWrapper>
);

const ProjectWrapper = styled.div`
    margin-bottom: 30px;

    .see-more {
        font-size: 14px;
        i {
            font-size: 18px;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            line-height: 16px;
        }
    }
`;

const Header = () => {
    const [isActive, setActive] = useState(false);

    useInterval(() => {
        // Your custom logic here
        setActive(!isActive);
    }, 750);

    return (
        <span>
            <h1>
                Hello! My name is Bryden, nice to meet you
                <FavoriteIcon isActive={isActive}>
                <MaterialIcon icon="favorite" />
                </FavoriteIcon>
            </h1>
            <p>
                As you may have noticed this website is a little bare and that's because its currently under construction (as of March 2019, just so you know that it 
                won't look like this forever). When I'm not procrastinating updating my website 
                you can usually find me coding, watching esports or surfing.
            </p>
            <p>
                If you're interested in what I do you can check me out on <a href="https://www.linkedin.com/in/brydenfogelman/">LinkedIn</a> or <a href={resume}>download my resume</a>.
            </p>
        </span>
    );
};

const Home = () => {
  return (
    <Wrapper>
        <Header />
        <ProjectList title="Experience" projects={experiences}/>
        <ProjectList title="Projects" projects={projects}/>
    </Wrapper>
  );
};

export default Home;
